<template>
	<div class="user-detail">
		<div class="row margin-0">
			<div class="col-md-12 pad-0">
				<div class="parent">
					<div class="parent-head">
						<ul>
							<li @click="tab='About'" :class="{ active: tab === 'About' }">
								About
							</li>
              <li @click="tab='Education'" :class="{ active: tab === 'Education' }" v-if="user.user_type==='TEACHER'">
								Education &amp; Social Links
							</li>
              <li @click="tab='Subscriptions'" :class="{ active: tab === 'Subscriptions' }">
								Subscriptions
							</li>
              <li @click="tab='Transactions'" :class="{ active: tab === 'Transactions' }">
								Transactions
							</li>
              <li @click="tab='Enquires'" :class="{ active: tab === 'Enquires' }">
								Enquires
							</li>
              <li @click="tab='Enrollments'" :class="{ active: tab === 'Enrollments' }">
								Enrollments
							</li>
              <li @click="getSkillSet();tab='SkillSet'" :class="{ active: tab === 'SkillSet' }">
								{{user.user_type==='TEACHER'?'Skill Set':'Wishlist'}}
							</li>
              <li @click="tab='Coupons'" :class="{ active: tab === 'Coupons' }">
								Coupons
							</li>
              <li @click="tab='Devices'" :class="{ active: tab === 'Devices' }">
								Devices
							</li>
						</ul>
					</div>
					<div class="parent-body">
            <div class="row margin-0">
              <div class="col-12 pad-0">
                <div class="row margin-0" v-if="tab==='About'">
                  <div class="row margin-0">
                    <div class="col-10 pad-0">
                      <div class="row margin-0">
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">User ID</label>
                            <p>{{user.user_id}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">First Name</label>
                            <p>{{user.first_name}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Last Name</label>
                            <p>{{user.last_name}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Mobile</label>
                            <p>{{user.mobile}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Email Address</label>
                            <p>{{user.email}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Gender</label>
                            <p>{{user.gender || '-'}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Go Coins</label>
                            <p>{{user.credits}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">User Type</label>
                            <p>{{user.user_type}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Currency</label>
                            <p>{{user.region}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Teaching Mode</label>
                            <p>{{user.mode || '-'}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Free Demo</label>
                            <p>{{user.demo || '-'}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Entity Type</label>
                            <p>{{user.entity_type || '-'}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Date of Birth</label>
                            <p>{{user.dob?$filters.fullDate(user.dob):'-'}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Status Date</label>
                            <p>{{user.status_date?$filters.fullDateTime(user.status_date):'-'}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Status</label><br>
                            <select name="" id="" v-model="status" class="form-control form-control-sm margin-right-10" style="max-width: 40%;display:inline-block;">
                              <template v-if="lookupsSelector && lookupsSelector.USER_STATUS">
                                <template v-for="(item, idx) in lookupsSelector.USER_STATUS.codes" :key="idx">
                                  <option :value="item.code">{{item.description}}</option>
                                </template>
                              </template>
                            </select>
                            <button class="btn btn-sm btn-success" :disabled="status===user.status" @click="updateUserStatus()">Submit</button>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Joined On</label>
                            <p>{{$filters.fullDateTime(user.created_on)}}</p>
                          </div>
                        </div>
                        <div class="col-4 pad-left-0">
                          <div class="info-block">
                            <label for="">Location</label>
                            <p>{{user.address?user.address.name||user.address:'-'}}</p>
                          </div>
                        </div>
                        <div class="col-12 pad-left-0">
                          <div class="info-block">
                            <label for="">About</label>
                            <p>{{user.description || '-'}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-2 pad-0">
                      <img :src="user && user.thumbnail?user.thumbnail:'/user.webp'" style="max-width:180px;margin-bottom:10px;filter:brightness(0.7);" alt=""> 
                      <rating-detail v-if="user.rating" :rating="user.rating" :view="user.user_type" />
                    </div>
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Education'">
                  <div class="col-12 pad-0">
                    <h5>Education</h5>
                    <template v-if="user.credentials && user.credentials.education">
                      <template v-for="(item, idx) in user.credentials.education" :key="idx">
                        <div class="row margin-0">
                          <div class="col-5 pad-left-0">
                            <p>- {{item.title}}</p>
                            <p><a v-if="item.file && item.file.url" class='text-success' :href="item.file.url" target="_blank">{{ item.file.name||'File'}}</a><span v-else>-</span></p>
                          </div>
                        </div>
                      </template>
                    </template>
                    <h5>Experience</h5>
                    <template v-if="user.credentials && user.credentials.education">
                      <template v-for="(item, idx) in user.credentials.experience" :key="idx">
                        <div class="row margin-0">
                          <div class="col-5 pad-left-0">
                            <p>- {{item.title}}</p>
                            <p><a v-if="item.file && item.file.url" class='text-success' :href="item.file.url" target="_blank">{{ item.file.name||'File'}}</a><span v-else>-</span></p>
                          </div>
                        </div>
                      </template>
                    </template>
                    <h5>Certifications</h5>
                    <template v-if="user.credentials && user.credentials.certification">
                      <template v-for="(item, idx) in user.credentials.certification" :key="idx">
                        <div class="row margin-0">
                          <div class="col-5 pad-left-0">
                            <p>- {{item.title}}</p>
                            <p><a v-if="item.file && item.file.url" class='text-success' :href="item.file.url" target="_blank">{{ item.file.name||'File'}}</a><span v-else>-</span></p>
                          </div>
                        </div>
                      </template>
                    </template>
                    <h5>Social Links</h5>
                    <template v-if="user.data">
                      <div class="row margin-0">
                        <div class="col-12 pad-left-0">
                            <p class="margin-bottom-5">- Website: {{user.data.website || '-'}}</p>
                          </div>
                          <div class="col-12 pad-left-0">
                            <p class="margin-bottom-5">- Video: {{user.data.video || '-'}}</p>
                          </div>
                          <div class="col-12 pad-left-0">
                            <p class="margin-bottom-5">- Facebook: {{user.data.facebook || '-'}}</p>
                          </div>
                          <div class="col-12 pad-left-0">
                            <p class="margin-bottom-5">- LinkedIn: {{user.data.linkedIn || '-'}}</p>
                          </div>
                        </div>
                    </template>
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Subscriptions'">
                  <div class="col-12 pad-0">
                    <Subscriptions :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Transactions'">
                  <div class="col-12 pad-0">
                    <Transactions :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Enquires'">
                  <div class="col-12 pad-0">
                    <Enquires :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Enrollments'">
                  <div class="col-12 pad-0">
                    <Enrollments :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Coupons'">
                  <div class="col-12 pad-0">
                    <Coupons :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='Devices'">
                  <div class="col-12 pad-0">
                    <Devices :user="user" />
                  </div>
                </div>
                <div class="row margin-0" v-if="tab==='SkillSet'">
                  <div class="col-12 pad-0">
                    <template v-if="skillsList && skillsList[0] && skillsList[0].courses_detail">
                      <ul class="list-inline">
                        <template v-for="(item, idx) in skillsList[0].courses_detail" :key="idx">
                          <li class="btn btn-outline-danger btn-sm margin-5">
                            {{item.parent_detail?item.parent_detail.topic+' - ':''}} {{item.title||'-'}}
                          </li>
                        </template>
                      </ul>
                    </template>
                    <template v-else>
                      Loading...
                    </template>
                  </div>
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Transactions from '../Transactions.vue'
import Subscriptions from '../Subscriptions.vue'
import Enquires from '../Enquires.vue'
import Enrollments from '../Enrollments.vue'
import Coupons from '../Coupons.vue'
import Devices from '../Devices.vue'
export default {
  components: {
    Transactions,
    Subscriptions,
    Enquires,
    Enrollments,
    Coupons,
    Devices
  },
	props: ["user"],
	data() {
		return {
      tab: "About",
      addressList: null,
      skillsList: null,
			msg: null,
      status: null,
		};
	},
	created() {
    // this.getAddressList();
    this.status = this.user.status;
  },
  watch: {
    'user': function(newVal, oldVal) {
      this.status = this.user.status;
    }
  },
	methods: {
    updateUserStatus() {
      const that = this;
      that.$cs.user
        .update({
            resource: `${that.$cs.user.api}${that.user.id}/status/`,
            data: {
              status: this.status
            }
        })
        .then(
          data => {
            that.$emit('updated');
          },
          () => {
            console.log("error");
          }
        );
    },
    getSkillSet() {
      const that = this;
      that.$cs.user
        .list({
            resource: `${that.$cs.user.api}${this.user.id}/profiles/`,
        })
        .then(
          data => {
            that.skillsList = data.data;
          },
          () => {
            console.log("error");
          }
        );
    }
  },
};
</script>
<style scoped lang="stylus">
.address-list
  list-style none
  padding 0px
  margin 0px
  display flow-root
  li
    float left
    width 31.2%
    margin 1%
    border 1px solid #dddddd
    padding: 14px;
    background-color: antiquewhite;
    border-radius 4px
    p
      margin-bottom 5px
      b
        font-weight bold
</style>
